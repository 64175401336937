import React, { useState } from 'react'
import ScrollToTop from '../components/ScrollToTop'
import PaiementStatus from '../components/PaiementStatus'

function PaiementStatusPage() {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <ScrollToTop />
            <PaiementStatus />
        </div>
    )
}

export default PaiementStatusPage