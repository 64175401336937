import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
 0% { height: 200px; width: 200px; }
 30% { height: 250px; width: 250px; border-radius: 125px }
 100% { height: 200px; width: 200px; }
`

export const BidContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    justify-content: center;
    padding: 25px 150px;
    width: 100%;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1100px){
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 25px;
        grid-template-columns: 1fr;
    }
`

export const BidWrapper = styled.div`
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: center; */
    /* justify-content: space-between; */
    grid-gap: 30px;

    @media screen and (max-width: 1100px){
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
`

export const PhotosSection = styled.img`
    width: 600px;
    height: 600px;
`

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1100px){
        width: 70%;
        /* margin-left: 9px; */
    }
`

export const RightSection = styled.div`
    /* line-height: 2px; */
`

export const ProductTitle = styled.h1`
    /* margin-top: 50px; */
    margin-bottom: 25px;

    @media screen and (max-width: 1100px){
        text-align: center;
    }
`

export const OfferSection = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 1100px){
        padding: 15px;
    }
`

export const OfferDescription = styled.p`
    text-align: justify;
    font-size: medium;
    color: aliceblue;
    margin-bottom: 35px;
`

export const Descendent = styled.div`
    display: flex;
    align-items: center;
    background-color: beige;
    padding: 10px 30px;
    margin-top: 15px;

    @media screen and (max-width: 1100px){
        width: 350px;
    }
`

export const DescendentText = styled.p`
    font-size: large;

    @media screen and (max-width: 1100px){
        font-size: medium;
    }
`

export const InputOfferSection = styled.div`
    display: flex;
`

export const ProductDescription = styled.p`
    color: ${props => props.white ? "#fff" : "#000"};
    margin-bottom: 35px;
    

    @media screen and (max-width: 1100px){
        text-align: justify;
    }
`
export const BidOfferInput = styled.input`
    padding: 16px 16px;
    margin-right: 16px;
    border: 1px solid #1e272e;
    border-radius: 4px;

    @media screen and (max-width: 1100px){
        width: 130px;
    }
`

export const FormButton = styled.button`
    background: rgba(30, 39, 46,1.0);
    padding: 13px 50px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background: #fff;
        color: #000;
        transition: all 0.2s ease-in-out;
    }

    /* @media screen and (max-width: 1100px){
        width: 140px;
    } */
`

export const BidRoom = styled.div`
    margin-top: 17px;
`

export const BidRoomText = styled.h2`
    text-align: center;
    font-size: large;
    margin-bottom: 7px;
`

export const ParticipationButton = styled.div`
    margin-top: 2em;
`

export const RegisterButton = styled.button`
    width: 100%;
    background: ${props => props.primary ? "#fff" : "#FF6B00"};
    color: ${({ dark }) => (dark ? '#010606' : '#fff')};
    padding: 0.25em 1em;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: ${({ fontSmall }) => (fontSmall ? '16px' : '18px')};

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${props => props.primary ? "#fff" : "#000"};
    }
`

export const ProductPrice = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const StartPriceText = styled.h2`
    font-size: large;
    color: red;
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: green;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;

    @media screen and (max-width: 620px) {
        margin-bottom: 10px;
    }
`

export const StartPriceValue = styled.h2`
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    /* background: #e74c3c; */
    
    border: 1px solid ${props => props.green ? 'green' : "#e74c3c"};
    text-align: center;
    color: ${props => props.green ? 'green' : "#e74c3c"};
    border-radius: ${props => props.green ? '0px' : "10px"};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const Text = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 18px;
`

export const Span = styled.span`
    margin-left: 5px;
    margin-right: 5px;
    color: #FF6B00;
`

export const CountdownWrapper = styled.div`
    background: #ecf0f1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #FF6B00;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.5);

`

export const PriceCountdown = styled.h1`
    color: #FF6B00;
    font-size: 70px;
    letter-spacing: 10px;
`

export const BuzzerButton = styled.div`
    background: #2ecc71;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin-top: 50px;
    box-shadow: 0 10px 15px rgba(0,0,0,0.6);
    cursor: pointer;
`

export const Image = styled.img`
    width: 50%;
    height: 50%;
    margin-bottom: 30px;
    margin-top: 20px;
`