import React, { useState } from 'react'
import Logo from '../../images/logo-dark.png'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";
import {
    Container,
    FormReset,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormWrap,
    Icon,
    IconLogo
} from '../Signin/SigninElements'
import { axiosInstance } from '../../app/api';

const ResetPassword = () => {

    const [password, setPassword] = useState('')

    const { userId, token } = useParams()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const onChange = (e) => {
        setPassword(e.target.value)
    }

    const onSubmit = async () => {
        setIsLoading(true)
        await axiosInstance.post(`/api/users/reset-password/${userId}/${token}`, { password: password })
            .then((res) => {
                toast.success(res.data)
                navigate('/')
            })
            .catch((e) => {
                toast.error(e)
            })
        setIsLoading(false)
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/"><IconLogo src={Logo} /></Icon>
                    <FormContent>
                        <FormReset>
                            <FormH1>Saisissez votre nouveau mot de passe</FormH1>

                            <FormInput
                                type='password'
                                placeholder='Nouveau mot de passe'
                                id='password'
                                name='password'
                                value={password}
                                onChange={onChange}
                                required
                            />

                            <FormButton onClick={onSubmit}>
                                {!isLoading ?
                                    "Envoyer" :
                                    <ClipLoader color='orange' size={20} />
                                }
                            </FormButton>
                        </FormReset>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    )
}

export default ResetPassword