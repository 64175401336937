import React from 'react'
import ForgotPassword from '../components/ForgotPassword'
import ScrollToTop from '../components/ScrollToTop'

const ForgotPasswordPage = () => {
    return (
        <div>
            <ScrollToTop />
            <ForgotPassword />
        </div>
    )
}

export default ForgotPasswordPage