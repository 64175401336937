import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Logo from '../../images/logo-dark.png'
import { register, reset } from '../../features/auth/authSlice'
import ClipLoader from "react-spinners/ClipLoader";
import {
    Container,
    Form,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormLabel,
    FormWrap,
    Icon,
    Text,
    ConditionsText,
    IconLogo
} from './SignupElements'

const SignUp = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        username: '',
        tel: '',
        birthDate: ''
    })

    const { firstName, lastName, email, password, username, tel, birthDate } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        if (isSuccess || user) {
            navigate('/')
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            firstName,
            lastName,
            email,
            password,
            username: firstName + '_' + lastName,
            phoneNumber: tel,
            birthDate
        }

        dispatch(register(userData))
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/"><IconLogo src={Logo} /></Icon>
                    <FormContent>
                        <Form onSubmit={onSubmit}>
                            <FormH1>Créer un compte</FormH1>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <FormInput
                                    type='text'
                                    placeholder='Prénom'
                                    id='firstName'
                                    name='firstName'
                                    value={firstName}
                                    required
                                    style={{ marginRight: '12px' }}
                                    onChange={onChange}
                                />
                                <FormInput
                                    type='text'
                                    id='lastName'
                                    name='lastName'
                                    value={lastName}
                                    onChange={onChange}
                                    placeholder='Nom de famille'
                                    required
                                />
                            </div>

                            <FormInput
                                type='email'
                                id='email'
                                name='email'
                                value={email}
                                onChange={onChange}
                                placeholder='Email'
                                required
                            />

                            <FormInput
                                type='password'
                                id='password'
                                name='password'
                                value={password}
                                onChange={onChange}
                                placeholder='Mot de passe'
                                required
                            />

                            <FormInput
                                type='tel'
                                id='tel'
                                name='tel'
                                value={tel}
                                onChange={onChange}
                                placeholder='Numéro de téléphone'
                                required
                            />

                            <FormLabel>Date de naissance</FormLabel>
                            <FormInput
                                type='date'
                                id='birthDate'
                                name='birthDate'
                                value={birthDate}
                                onChange={onChange}
                                placeholder='Date de naissance'
                                max='2005-01-01'
                                required
                            />

                            <FormButton disabled={isLoading ? true : false}>
                                {!isLoading ? "S'inscrire" :
                                    <ClipLoader color='orange' />
                                }

                            </FormButton>

                            <ConditionsText>En cliquant sur S'inscrire, vous confirmez avoir
                                lu et accepté les <Text to="/terms-and-conditions" target={'_blank'}>Conditions d'utilisation</Text>.
                                Veuillez vous référer à notre <Text to="/privacy-policy" target={'_blank'}>Avis sur les données personnelles</Text> pour toute information concernant le
                                traitement de vos données.</ConditionsText>
                        </Form>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    )
}

export default SignUp