import React, { useState, useEffect } from 'react'

import noBids from '../../images/no-bids.svg'
import GridLoader from "react-spinners/GridLoader";
import axios from 'axios';
import { axiosInstance } from '../../app/api';
import {
  BidDetails,
  ProductsH1,
  ProductCard,
  ProductCategory,
  ProductDescription,
  ProductName,
  ProductPhoto,
  ProductPrice,
  ProductsContainer,
  ProductsWrapper,
  StartPriceText,
  StartPriceValue
} from './OnGoingBidsElements'

const OnGoingBidsSection = () => {

  const API_URL = '/api/bid-rooms/'

  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(false)

  const getBids = async () => {
    setLoading(true)
    const response = await axiosInstance.get(API_URL + 'status/1')

    if (response.data)
      setBids(response.data)
    setLoading(false)
  }

  useEffect(() => {
    getBids()
  }, [])

  return (
    <ProductsContainer>
      <ProductsH1>Nos enchères en cours</ProductsH1>
      {
        loading ? <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 450,
        }}>
          <GridLoader color='#FF6B00' size={40} />
        </div> : (bids.length === 0) ?
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 450,
          }}>
            <img alt="No Bids" src={noBids} style={{
              width: '50%',
              height: '50%'
            }} />
            <p style={{
              marginTop: 50
            }}>Pas d'enchère en cours</p>
          </div>
          : <>
            <ProductsWrapper>
              {bids.map((bid, index) => {
                return (
                  <ProductCard key={index}>
                    <ProductCategory>
                      {bid.category.name}
                    </ProductCategory>

                    <ProductPhoto src={bid.product.images[0].original} />

                    <hr
                      style={{
                        width: '70%',
                        height: '0.5px',
                        background: 'rgba(30, 39, 46, 0.1)',
                        borderRadius: '1px'
                      }}
                    />

                    <BidDetails>
                      <div style={{
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <ProductName to={`/bid/${bid._id}`}>
                          {bid.product.name}
                        </ProductName>
                      </div>

                      <div style={{
                        height: '49px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <ProductDescription>
                          {bid.product.description}
                        </ProductDescription>
                      </div>
                      <ProductPrice>
                        <StartPriceText>Prix magasin:</StartPriceText>
                        <StartPriceValue>{bid.product.price + ' TND'}</StartPriceValue>
                      </ProductPrice>
                    </BidDetails>

                  </ProductCard>
                )
              })}
            </ProductsWrapper>
          </>
      }
    </ProductsContainer>
  )
}

export default OnGoingBidsSection