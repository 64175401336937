import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import LegalNotice from '../components/LegalNotice'
import ScrollToTop from '../components/ScrollToTop'

function Legal() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />

            <Navbar toggle={toggle} />

            <LegalNotice />

            <Footer />
        </div>
    )
}

export default Legal