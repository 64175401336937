import axios from "axios";
import { axiosInstance } from '../../app/api';
import { toast } from "react-toastify";

const API_URL = '/api/users/'

// Send reset link
const sendResetLink = async (userData) => {
  const response = await axiosInstance.post(API_URL + 'reset-password', userData)
  
  if (response.data) {
    toast.success('Un email de réinitialisation de mot de passe a été envoyé')
  }

}

// Register user
const register = async (userData) => {
  const response = await axiosInstance.post(API_URL, userData)

  if (response.data) {
    toast.success('Un email de vérification a été envoyé')
  }

  // return response.data
}

// Login user
const login = async (userData) => {
  const response = await axiosInstance.post(API_URL + 'login', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const authService = {
  sendResetLink,
  register,
  logout,
  login,
}

export default authService