export const HomeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Enchère en ligne',
    headline: 'Accessible à tous le monde',
    description: 'Enchèrir dès maintenant, il est devenu plus facile et plus rapide avec Mise.tn',
    buttonLabel: 'Rejoindre maintenant',
    imgStart: false,
    img: require('../../images/info1.png'),
    alt: 'Bidding Online',
    dark: true,
    primary: true,
    darkText: false
}

export const HomeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Enchère en ligne',
    headline: 'Accessible à tous le monde',
    description: 'Enchèrir dès maintenant, il est devenu plus facile et plus rapide avec Mise.tn',
    buttonLabel: 'Rejoindre maintenant',
    imgStart: false,
    img: require('../../images/info2.png'),
    alt: 'Bidding Online',
    dark: false,
    primary: true,
    darkText: true
}