import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { axiosInstance } from '../../app/api';

import {
    ProductsContainer,
    ProductsH1,
    ProductsWrapper,
    Span, Table, Td, Th, Tr,
    ButtonWrapper,
    ButtonWrapperLoading
} from './PaiementValidationElements'

function PaiementValidationSection() {
    const API_URL = 'https://mise.tn/api/bid-participations/payment'
    // const API_URL = 'http://localhost:5000/api/bid-participations/payment'

    const [loading, setLoading] = useState(false)
    const { user } = useSelector((state) => state.auth)
    const [checkout, setCheckout] = useState({
        ddp: 0,
        subTotal: 0,
        total: 0
    })

    useEffect(() => {
        let bid = JSON.parse(window.localStorage.getItem('chosen_bid'))
        setCheckout(bid)
    }, [])

    const proceedPayment = async () => {
        setLoading(true)
        const response = await axiosInstance.post(API_URL, {
            userId: user._id,
            bidId: checkout.bidId
        }, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        })

        if (response)
            window.location.href = response.data.payUrl

        setLoading(false)
    }

    return (
        <ProductsContainer>
            <ProductsH1>Validation de paiement</ProductsH1>
            <ProductsWrapper style={{
                width: "50%",
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <Table>
                    <Tr>
                        <Th>DDP à:</Th>
                        <Td>{checkout.ddp}</Td>
                    </Tr>
                    <Tr>
                        <Th>Sous-total:</Th>
                        <Td>{checkout.subTotal} <Span>TND</Span></Td>
                    </Tr>
                    <Tr>
                        <Th>Total:</Th>
                        <Td>{checkout.total} <Span>TND</Span></Td>
                    </Tr>

                </Table>

                {
                    loading ?
                        <ButtonWrapperLoading>
                            Loading...
                        </ButtonWrapperLoading>
                        :
                        <ButtonWrapper onClick={proceedPayment}>
                            PAYER
                        </ButtonWrapper>
                }
            </ProductsWrapper>
        </ProductsContainer>
    )
}

export default PaiementValidationSection