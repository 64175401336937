import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../app/api';
import 'react-image-gallery/styles/css/image-gallery.css'

function EmailConfirmation() {

    const { token } = useParams()
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)

    const getResponse = async () => {
        await axiosInstance.get(`/api/users/confirmation/${token}`)
            .then((res) => {
                setMessage(res.data.message)
            })
            .catch(e => {
                setErrorMessage(e.response.data.message)
            })
        setLoading(false)
    }

    useEffect(() => {
        getResponse()
    }, [])

    return (
        <div style={{
            display: 'flex',
            height: '100vh',
            minHeight: '100vh',
            backgroundColor: '#065f46',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {
                loading ? <p style={{ color: 'white' }}>Loading...</p> :
                    errorMessage !== '' ? (
                        <div style={{
                            display: 'flex',
                            width: '60%',
                            height: '45%',
                            backgroundColor: '#7F1D1D',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: '0px solid white'
                        }}>
                            <h2 style={{
                                color: 'white'
                            }}>{errorMessage}</h2>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="85" height="85"
                                fill="currentColor"
                                color='white'
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>

                            <h3 style={{
                                color: 'white'
                            }}><a href='https://mise.tn/' style={{
                                fontSize: 22,
                                color: 'white'
                            }}>Retour à l'accueil</a></h3>
                        </div>
                    ) : (
                        <div style={{
                            display: 'flex',
                            width: '60%',
                            height: '45%',
                            backgroundColor: '#064e3b',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: '0px solid white'
                        }}>
                            <h2 style={{
                                color: 'white'
                            }}>{message}</h2>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="85"
                                height="85"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                color='white'
                            >
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                />
                            </svg>

                            <a href='https://mise.tn/sign-in' style={{
                                fontSize: 22,
                                color: 'white'
                            }}>Se connecter</a>
                        </div>
                    )
            }
        </div >
        // <div>
        //     <p>{token}</p>
        // </div>
    )
}

export default EmailConfirmation