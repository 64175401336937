import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop'
import ProfileComponent from '../components/Profile'

function Profile() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />

            <Navbar toggle={toggle} />

            <ProfileComponent />

            <Footer />
        </div>
    )
}

export default Profile