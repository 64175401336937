import React from 'react'
import ResetPassword from '../components/ResetPassword'
import ScrollToTop from '../components/ScrollToTop'

const ResetPasswordPage = () => {
    return (
        <div>
            <ScrollToTop />
            <ResetPassword />
        </div>
    )
}

export default ResetPasswordPage