import styled from "styled-components";

export const CategoriesContainer = styled.div`
    background: #fff;
    /* display: flex; */
    /* justify-content: space-around; */
    /* align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 250px;
    position: relative;
    z-index: 1; */

    max-width: 1200px;
    /* margin: 0 auto; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 10px;

    @media screen and (max-width: 900px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 5px;
    }

    @media screen and (max-width: 300px){
        grid-template-columns: 1fr 1fr;
    }
`

export const CategoryCard = styled.button`
    display: flex;
    background: ${({ selected }) => selected ? '#FF6B00' : '#fff'};
    color: ${({ selected }) => selected ? '#fff' : '#000'};;
    height: 90%;
    margin: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 7px;
    border-radius: 25px;

    &:hover {
        background: #FF6B00;
        color: #ecf0f1;
    }

    @media screen and (max-width: 768px) {
        margin: 5px
    }
`

export const CategoryIcon = styled.img`
    width: 150px;
    height: 150px;

    @media screen and (max-width: 768px) {
        width: 70px;
        height: 70px;
    }
`

export const CategoryName = styled.p`
    font-size: 18px;

    @media screen and (max-width: 768px) {
        font-size: 10px;
    }
`