import styled from "styled-components";

export const BidContainer = styled.div`
    /* background: rgba(75, 207, 250,0.1); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
    width: 100%;
    height: 600px;
    position: relative;
    z-index: 1;
`

export const Image = styled.img`
    width: 50%;
    height: 50%;

    @media screen and (max-width: 780px) {
        width: 70%;
    height: 70%;
    }
`

export const H1 = styled.h1`
    margin-top: 50px;
    font-size: 30px;

    @media screen and (max-width: 780px) {
        margin-top: 0px;
        font-size: 20px;
    }
`