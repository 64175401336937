import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    bidId: '',
    ddp: '',
    subTotal: 0,
    total: 0
}

export const paiementSlice = createSlice({
    name: 'paiement',
    initialState,
    reducers: {
        reset: (state) => initialState
    }
})

export const {reset} = paiementSlice.actions
export default paiementSlice.reducer