import React from 'react'
import { BidContainer, H1, P, H2 } from './PrivacyElements'

function AboutUs() {
    return (
        <div>
            <BidContainer>
                <H1>Qui sommes-nous</H1>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <P>
                        La Société Bargain Trade (ci-après « <strong>S.B.T.</strong> »), société à responsabilités limités (S.A.R.L.), exploite une Plateforme de commerce électronique (e-commerce) sur le territoire Tunisien nommée « <strong>Mise.tn</strong> » composée d'un Site Web et d'une Application mobile.
                    </P>

                    <P>
                        La « <strong>S.B.T.</strong> » permet à ses participants d'acheter pratiquement tout type d'article aux meilleurs prix qui soient. Vous souhaitez réaliser de bonnes affaires, ne ratez pas nos enchères, une large sélection d'articles neufs et de service mis en ligne (High-tech « Smartphone, Tablette, PC, TV, Consoles de jeux, … », Electroménager, Matériels professionnels pour « Cafétéria, Restauration, Pâtisserie, Boulangerie, Hôtellerie, … », Autos, Motos, Loisirs « Voyages, Vacances, … », Mobilier, Immobilier « Terrains, Appartements, …), Services « Soins du visage, des mains et pieds, Coiffures, … », et autres à des Prix réduits. <strong>Mise.tn</strong> vous permettra de profiter aux mieux de nos produits remisés adaptés à votre budget, chose qu'aucun fournisseur ne pourra vous accordez.
                    </P>

                    <P>
                        Le siège social est à l'Avenue Jugurtha N° 78 – Mutuelleville – 1082 Tunis(ie), Matricule Fiscale 1790199 K / N / M / 000, Registre de Commerce N° C03396612022, E-mail : support@mise.tn, Téléphone : 25 87 55 55.
                    </P>
                </div>
            </BidContainer>
        </div>
    )
}

export default AboutUs