import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements'

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>Liens utiles</FooterLinkTitle>
                                <FooterLink to="/about-us">Qui sommes-nous?</FooterLink>
                                <FooterLink to="/how-it-works">Comment ça marche?</FooterLink>
                                <FooterLink to="/terms-and-conditions">Termes et conditions</FooterLink>
                                <FooterLink to="/privacy-policy">Politique de confidentialité</FooterLink>
                                <FooterLink to="/legal-notice">Mentions légales</FooterLink>
                            </FooterLinkItems>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>

                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to='/'>
                                Mise.tn
                            </SocialLogo>
                            <WebsiteRights>Mise.tn © {new Date().getFullYear()} Tous les droits sont réservés.</WebsiteRights>
                            <SocialIcons>
                                <SocialIconLink href='https://www.facebook.com/profile.php?id=100091645105578&mibextid=ZbWKwL' target="_blank" aria-label="Facebook">
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink href='https://www.instagram.com/mise.tn23' target="_blank" aria-label="Instagram">
                                    <FaInstagram />
                                </SocialIconLink>
                                {/* <SocialIconLink href='https://youtube.com/' target="_blank" aria-label="Youtube">
                                    <FaYoutube />
                                </SocialIconLink>
                                <SocialIconLink href='https://twitter.com/' target="_blank" aria-label="Twitter">
                                    <FaTwitter />
                                </SocialIconLink>
                                <SocialIconLink href='https://linkedin.com/' target="_blank" aria-label="LinkedIn">
                                    <FaLinkedin />
                                </SocialIconLink> */}
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer