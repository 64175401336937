import React from 'react'
import privacy from '../../images/privacyPolicy.png'
import { BidContainer, H1, P, Image } from './PrivacyElements'

function TermsConditions() {
    return (
        <div>
            <BidContainer>
                <H1>Conditions Générales d'Utilisation</H1>

                <div style={{
                    marginTop: 25
                }}>
                    <P>
                        Les présentes conditions générales de vente et tous les autres documents mis en ligne sur le site « www.mise.tn » forment un Contrat électronique aux termes de la loi n°2000-83 du 9 aout 2000, relative aux échanges et au commerce électronique, entre :
                    </P>

                    <P>
                        <strong>Bargain Trade S.A.R.L.</strong>, société de droit tunisien dont le Matricule Fiscal est le 1790199 K / N / M / 000, et enregistrée au registre de commerce sous le n° C03396612022. Son siège social est sis au 78 Avenue Jugurtha – Mutuelleville – 1082 Tunis(ie). D'une part,
                    </P>

                    <P>
                        Et,
                    </P>

                    <P>
                        Le client :  toute personne physique ou morale, agissant en tant que particulier ou professionnel, qui commande auprès de Bargain Trade « <strong>mise.tn</strong> » un/des produit(s). D'autre part.
                    </P>

                    <P>
                        Les présentes Conditions Générales s'appliquent à tous les utilisateurs de notre Plateforme et à tous les services associés.
                    </P>

                    <ul style={{
                        marginTop: 15,
                        marginLeft: 35,
                        lineHeight: 2
                    }}>
                        <li>
                            En utilisant notre Plateforme, vous acceptez et vous respectez ces conditions générales dans leur intégralité. Si vous n'êtes pas d'accord avec ces conditions générales ou une partie de ces conditions, vous ne devez pas utiliser notre Plateforme.
                        </li>
                        <li>
                            Il faut avoir plus de 18 ans pour s'inscrire sur notre Plateforme. En acceptant les présentes conditions générales, vous nous garantissez et vous déclarez que vous êtes majeur (vous avez au moins 18 ans).
                        </li>
                        <li>
                            Pour vous inscrire sur notre site, vous devez remplir le formulaire d'inscription disponible sur notre Plateforme (veillez à bien remplir le formulaire : Nom, Prénom, Date de naissance, Pseudo « pseudonyme = Faux nom », Adresse e-mail, N° téléphone et Mot de passe). Vous déclarez que toutes les informations fournies dans le formulaire d'inscription sont exactes.
                        </li>
                        <li>
                            Veuillez à bien garder votre mot de passe confidentiel (secret) et à nous informer immédiatement par écrit si vous avez eu connaissance d'une divulgation de votre mot de passe.
                        </li>
                        <li>
                            Votre compte doit être utilisé exclusivement par vous et vous ne devez en aucun cas transférer votre compte à un tiers. Si vous autorisez un tiers à gérer votre compte en votre nom, cela se fera à vos propres risques et périls.
                        </li>
                        <li>
                            En cas de suspicion de fraude et/ou si vous n'honorez pas nos conditions générales que vous avez acceptées auparavant, nous pouvons suspendre ou annuler votre compte à tout moment.
                        </li>
                        <li>
                            Vous pouvez, à tout moment, vous désinscrire et supprimer votre compte sur notre Plateforme, en nous contactant aux coordonnées indiquées ci-dessus.
                        </li>
                        <li>
                            Notre Plateforme vous propose un service de vente aux enchères en ligne, ce n'est pas un jeu de chance ou de hasard c'est une vraie enchère basée sur la méthode néerlandaise (enchère descendante et non ascendante). « Une vente aux enchères néerlandaise désigne également un type de vente aux enchères dans lequel le prix d'un article est abaissé jusqu'à ce qu'une offre lui soit faite. La première offre faite est l'offre gagnante et aboutit à une vente. Cela contraste avec les options typiques, où le prix augmente au fur et à mesure que les enchérisseurs se font concurrence  ». Dans son principe, on peut dire l'enchère descendante est identique à l'enchère fermée au premier prix en termes de stratégie. L'enchérisseur va se baser sur l'évaluation du montant initial auquel il souhaite faire son acquisition (<strong>son budget</strong>).
                        </li>
                        <li>
                            Vous reconnaissez et acceptez que notre Plateforme est une application de ventes aux enchères en ligne, et vous vous engagez à acheter le ou les produits enchéris, dès la confirmation de l'enchère.
                        </li>
                        <li>
                            Vous reconnaissez et acceptez que notre Plateforme est une application de ventes aux enchères en ligne, et vous vous engagez à acheter le ou les produits enchéris, dès la confirmation de l'enchère.
                        </li>
                        <li>
                            Un contrat de vente (Facture) sera émis par « <strong>S.B.T</strong> », cependant la livraison sera assurée selon les conditions commerciales du fournisseur du produit (délais et prix gratuite ou payante).
                        </li>
                        <li>
                            Les frais de livraison, les frais d'emballage, les frais de manutention, les frais administratifs, les frais d'assurance et les autres frais et charges ne seront à la charge de l'acheteur que si cela est expressément et clairement indiqué dans la liste et le descriptif des produits concernés.
                        </li>
                        <li>
                            Le fournisseur des produits affichés sur notre plateforme certifie que ses produits sont neufs, de bonne qualité, adaptés et sûrs pour tout usage spécifié dans le descriptif des produits et conformes à tous les aspects matériels, ainsi que toute autre description des produits fournies ou mis à disposition de l'acheteur par le vendeur.
                        </li>
                        <li>
                            Source: <a target="_blank" href='https://www.investirsorcier.com/definition-de-la-vente-aux-encheres-aux-pays-bas/' >InvestirSorcier</a>
                        </li>
                        <li>
                        Le fournisseur des produits affichés sur notre plateforme garantit le bon fonctionnement et garantit qu'il détient un titre de propriété et est le seul propriétaire légal et bénéficiaire des produits qu'il vend et que ces produits ne sont soumis à aucun droit des tiers ou restriction ou prohibition, y compris en ce qui concerne les droits de propriété intellectuelle et / ou toute enquête ou procédure pénale, d'insolvabilité ou fiscale
                        </li>
                        <li>
                        Les retours de produits par les acheteurs seront gérés par le fournisseur conformément aux conditions définies par sa société.
                        </li>
                        <li>
                        Les remboursements concernant les produits retournés seront gérés par la « <strong>S.B.T</strong> » et le fournisseur conformément aux conditions définies par le contrat liant les deux parties et qui peut être modifiée de temps à autre. Nos règles sur les remboursements seront exercées à notre discrétion, sous réserve du respect des lois applicables dans le Territoire. Nous pouvons toutefois offrir des remboursements, à notre discrétion.
                        </li>
                        <li>
                        Les remboursements seront en bons d'achat ou virement bancaire ou de tout autre moyen que nous pouvons déterminer de temps à autre bien sûr déduits des frais de livraison ou de toutes autres charges.
                        </li>
                        <li>
                        Le paiement de la participation aux enchères sera en ligne (par carte bancaire ou postale) et ne sera jamais remboursé.
                        </li>
                        <li>
Le paiement du produit remporté en enchère sera en espèces ou par TPE chez « <strong>S.B.T</strong> », par virement bancaire ou postale sur le compte de la « <strong>S.B.T</strong> », par chèques personnels (pour les sociétés chèques certifiés).
                        </li>
                    </ul>
                </div>
            </BidContainer>
        </div>
    )
}

export default TermsConditions