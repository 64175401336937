import React, { useState, useEffect } from 'react'
import GridLoader from "react-spinners/GridLoader";
import noBids from '../../images/no-bids.svg'
import { axiosInstance } from '../../app/api';

import {
  ProductsContainer,
  ProductsH1,
  ProductsWrapper,
  ProductCard,
  DateCard,
  ProductStartDate,
  ProductCategory,
  ProductPhoto,
  BidDetails,
  ProductName,
  ProductDescription,
  ProductPrice,
  NewPrice,
  OldPrice

} from "./FinishedBidsElements"

function FinishedBidsSection() {

  const API_URL = '/api/bid-rooms/'

  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(false)

  const getBids = async () => {
    setLoading(true)
    const response = await axiosInstance.get(API_URL + 'status/2')

    if (response.data)
      setBids(response.data)

    setLoading(false)
  }

  useEffect(() => {
    getBids()
  }, [])

  return (
    <ProductsContainer id="products">
      <ProductsH1>Nos enchères terminées</ProductsH1>
      {
        loading ? <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 450,
        }}>
          <GridLoader color='#FF6B00' size={40} />
        </div> : (bids.length === 0) ?
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 450,
          }}>
            <img src={noBids} alt="No Bids" style={{
              width: '50%',
              height: '50%'
            }} />
            <p style={{
              marginTop: 50
            }}>Pas d'enchère terminées</p>
          </div>
          :
          <>
            <ProductsWrapper>
              {bids.map((bid, index) => {
                return (
                  <ProductCard key={index}>
                    <DateCard>
                      <ProductStartDate>
                        Remporté par <br /> {bid.winner}
                      </ProductStartDate>
                    </DateCard>

                    <ProductCategory>
                      {bid.category.name}
                    </ProductCategory>

                    <ProductPhoto src={bid.product.images[0].original} />

                    <hr
                      style={{
                        width: '70%',
                        height: '0.5px',
                        background: 'rgba(30, 39, 46, 0.1)',
                        borderRadius: '1px'
                      }}
                    />

                    <BidDetails>
                      <div style={{
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <ProductName>
                          {bid.product.name}
                        </ProductName>
                      </div>

                      <div style={{
                        height: '49px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <ProductDescription>
                          {bid.product.description}
                        </ProductDescription>
                      </div>
                      <ProductPrice>
                        <NewPrice>{(bid.new_price == null ? '0' : bid.new_price) + ' TND'}</NewPrice>
                        <OldPrice>{bid.product.price + ' TND'}</OldPrice>
                      </ProductPrice>
                    </BidDetails>

                  </ProductCard>
                )
              })}
            </ProductsWrapper>
          </>
      }
    </ProductsContainer>
  )
}

export default FinishedBidsSection