import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import NotFound404 from '../components/NotFound'
import ScrollToTop from '../components/ScrollToTop'

function NotFound() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />

            <Navbar toggle={toggle} />

            <NotFound404 />

            <Footer />
        </div>
    )
}

export default NotFound