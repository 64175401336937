import styled from "styled-components"
import { Link } from "react-router-dom";

export const ProductsContainer = styled.div`
    background: rgba(75, 207, 250,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
    width: 100%;
    position: relative;
    z-index: 1;
`

export const ProductsH1 = styled.h1`
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 10px;
`

export const ProductsWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;

    @media screen and (max-width: 1150px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 900px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 620px){
        grid-template-columns: 1fr;
    }
`

export const ProductCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    /* max-height: 800px; */
    padding: 22px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        /* cursor: pointer; */
    }
`

export const DateCard = styled.div`
    height: 60px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
    background: #3498db;
    align-items: center;
    justify-content: center;
`

export const ProductStartDate = styled.p`
    text-align: center;
    color: #fff;
`

export const ProductCategory = styled.p`
overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;
`

export const ProductPhoto = styled.img`
    width: 200px;
    height: 200px;
`

export const BidDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 15px;
`

export const ProductName = styled(Link)`
    
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    overflow: hidden;
    color: black;
    font-weight: bold;
    font-size: medium;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

export const ProductDescription = styled.p`
    display: flex;
    font-size: small;
    margin-top: 15px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;
`

export const ProductPrice = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 17px;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 620px) {
        flex-direction: column;
    }
`

export const StartPriceText = styled.p`
    font-size: 16px;
    color: red;
    width: 50%;
    text-align: center;

    @media screen and (max-width: 620px) {
        margin-bottom: 10px;
    }
`

export const StartPriceValue = styled.p`
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50px;
    background: #e74c3c;
    text-align: center;
    color: #fff;
    border-radius: 10px;
`

export const BidRoom = styled.div`
    margin-top: 17px;
`

export const BidRoomText = styled.div`
    text-align: center;
    font-size: small;
    margin-bottom: 7px;
`

export const ParticipationButton = styled.div`

`

export const RegisterButton = styled.button`
    width: 100%;
    background: ${props => props.primary ? "#fff" : "#FF6B00"};
    color: ${({ dark }) => (dark ? '#010606' : '#fff')};
    margin-top: 1.2em;
    padding: 0.25em 1em;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: ${({ fontSmall }) => (fontSmall ? '16px' : '18px')};

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${props => props.primary ? "#fff" : "#000"};
    }
`