import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../app/api';
// import backgroudImage from '../../images/wallpapers/1.png'
// import backgroudImage1 from '../../images/wallpapers/2.jpg'
// import backgroudImage2 from '../../images/wallpapers/3.jpg'
// import { Button } from '../ButtonElement'
import {
    HeroContainer,
    // HeroBg,
    // ImageBg,
    // HeroContent,
    // HeroH1,
    // HeroP,
    // HeroBtnWrapper,
    // ArrowForward,
    // ArrowRight
} from './HeroElements'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import cssStyles from './styles.css'
import 'react-awesome-slider/dist/styles.css';
import { useSelector } from 'react-redux'

const HeroSection = () => {
    // const [hover, setHover] = useState(false)
    const [slides, setSlides] = useState([])
    const [loading, setLoading] = useState(true)
    // const { user } = useSelector((state) => state.auth)
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    // const onHover = () => {
    //     setHover(!hover)
    // }

    const getSlides = async () => {
        await axiosInstance.get(`/api/settings`)
            .then((res) => {
                setSlides(res.data[0].slides)
            })
            .catch(e => {
                console.log(e)
            })
        setLoading(false)
    }

    useEffect(() => {
        getSlides()
    }, [])


    return (
        <HeroContainer id="home">

            {
                loading ?
                    <div><p>loading...</p></div>
                    :
                    <AutoplaySlider
                        play={true}
                        cancelOnInteraction={false} // should stop playing on user interaction
                        interval={4000}
                        fillParent={true}
                        bullets={false}
                        className={cssStyles.mobile}
                    >
                        {
                            slides.map((slide, index) => {
                                return (
                                    <div data-src={slide.source} />
                                )
                            })
                        }
                    </AutoplaySlider>
            }
        </HeroContainer>

        // <HeroContainer id="home">
        //     <HeroBg>
        //         <ImageBg src={backgroudImage} />
        //     </HeroBg>
        //     <HeroContent>
        //         <HeroH1>Les enchères en ligne simplifiées et rapides</HeroH1>
        //         <HeroP>Créez votre compte maintenant et commencez à enchérir.</HeroP>
        //         {!user &&
        //             (<HeroBtnWrapper>
        //                 <Button to="/sign-up"
        //                     onMouseEnter={onHover}
        //                     onMouseLeave={onHover}
        //                     primary={true}
        //                 // dark={true}
        //                 >
        //                     Commencez maintenant {hover ? <ArrowForward /> : <ArrowRight />}
        //                 </Button>
        //             </HeroBtnWrapper>
        //             )
        //         }
        //     </HeroContent>
        // </HeroContainer>
    )
}

export default HeroSection