import React, { useState, useEffect } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";
import { axiosInstance } from '../../app/api';
import All from '../../images/categories/all.png'

import {
    CategoriesContainer,
    CategoryCard,
    CategoryIcon,
    CategoryName
} from "./CategoriesElements"

const CategoriesSection = ({ category, setCategory }) => {

    const API_URL = '/api/categories'

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)

    const getCategories = async () => {
        setLoading(true)
        const response = await axiosInstance.get(API_URL)

        if (response.data)
            setCategories(response.data)

        setLoading(false)
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <CategoriesContainer id="categories">
            {/* <CategoriesWrapper> */}

            <CategoryCard onClick={() => setCategory('all')} selected={category === 'all' ? true : false}>
                <CategoryIcon src={All} />
                <CategoryName>
                    All
                </CategoryName>
            </CategoryCard>
            {
                loading ? <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <PropagateLoader color='#FF6B00' size={40} />
                </div> :
                    categories.map((cat, index) => {
                        return (
                            <CategoryCard key={index} onClick={() => setCategory(cat._id)} selected={category === cat._id ? true : false}>
                                <CategoryIcon src={cat.photo} />
                                <CategoryName>
                                    {cat.name}
                                </CategoryName>
                            </CategoryCard>
                        )
                    })
            }

            {/* </CategoriesWrapper> */}
        </CategoriesContainer>
    )
}

export default CategoriesSection