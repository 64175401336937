import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import PaiementValidationSection from '../components/PaiementValidationSection'
import ScrollToTop from '../components/ScrollToTop'

function PaiementVerification() {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />

            <PaiementValidationSection />

            <Footer />
        </div>
    )
}

export default PaiementVerification;