import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages';
import SigninPage from './pages/signin';
import ForgotPasswordPage from './pages/forgot-password';
import SignUpPage from './pages/signup';
import BidDetailsPage from './pages/bid-details';
import OnGoingBidsPage from './pages/on-going-bids';
import FinishedBidsPage from './pages/finished-bids';
import PaiementVerification from './pages/paiement-verification';
import NotFound from './pages/404-not-found';
import Politique from './pages/privacy-policy';
import HowWorks from './pages/how-it-works';
import Legal from './pages/legal-notice';
import Terms from './pages/terms-conditions';
import About from './pages/about-us';
import EmailConfirmationPage from './pages/email-confirmation';
import Profile from './pages/profile';
import PaiementStatusPage from './pages/paiement-status';
import ResetPasswordPage from './pages/reset-password';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/on-going-bids" element={<OnGoingBidsPage />} exact />
          <Route path="/finished-bids" element={<FinishedBidsPage />} exact />
          <Route path="/sign-in" element={<SigninPage />} exact />
          <Route path="/sign-up" element={<SignUpPage />} exact />
          <Route path="/reset-password" element={<ForgotPasswordPage />} exact />
          <Route path="/users/reset-password/:userId/:token" element={<ResetPasswordPage />} exact />
          <Route path="/paiement-validation" element={<PaiementVerification />} exact />
          <Route path="/privacy-policy" element={<Politique />} exact />
          <Route path="/how-it-works" element={<HowWorks />} exact />
          <Route path="/legal-notice" element={<Legal />} exact />
          <Route path="/profile" element={<Profile />} exact />
          <Route path="/terms-and-conditions" element={<Terms />} exact />
          <Route path="/about-us" element={<About />} exact />

          <Route path="/bid/:id" element={<BidDetailsPage />} exact />
          <Route path="/users/confirmation/:token" element={<EmailConfirmationPage />} exact />
          <Route path="/paiement-status" element={<PaiementStatusPage />} exact />

          <Route path="*" element={<NotFound />} exact />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
