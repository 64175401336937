import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import noBids from '../../images/no-bids.svg'
import ProgressBar from "@ramonak/react-progress-bar";
import GridLoader from "react-spinners/GridLoader";
import { axiosInstance } from '../../app/api';

import {
  ProductsContainer,
  ProductsH1,
  ProductsWrapper,
  ProductCard,
  DateCard,
  ProductStartDate,
  ProductCategory,
  ProductPhoto,
  BidDetails,
  ProductName,
  ProductDescription,
  ProductPrice,
  StartPriceText,
  StartPriceValue,
  BidRoom,
  BidRoomText,
  RegisterButton,
  NoBidsWrapper,
  NoProductsMessage
} from "./ProductsElements"

const ProductSection = ({ category }) => {

  const API_URL = '/api/bid-rooms/'

  const [bids, setBids] = useState([])
  const [filteredBids, setFilteredBids] = useState([])
  const [loading, setLoading] = useState(false)

  function filterByCategory(item) {
    return item.category._id === category
  }

  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const payer = (transaction) => {
    if (user) {
      window.localStorage.setItem('chosen_bid', JSON.stringify(transaction))
      navigate('/paiement-validation')
    }
    else
      navigate('/sign-in')
  }

  const getBids = async () => {
    setLoading(true)
    const response = await axiosInstance.get(API_URL + `status/0`)

    if (response.data) {
      setBids(response.data)
      setFilteredBids(response.data)
    }

    setLoading(false)
  }

  const getBidsByUser = async () => {
    setLoading(true)
    const response = await axiosInstance.get(API_URL + `status/0/${user._id}`)

    if (response.data) {
      setBids(response.data)
      setFilteredBids(response.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (user == null) {
      getBids()
    }
    else {
      getBidsByUser()
    }
  }, [])

  useEffect(() => {
    let cats
    if (category === "all")
      setFilteredBids(bids)
    else {
      cats = bids.filter(filterByCategory)
      setFilteredBids(cats)
    }
  }, [category])

  return (
    <ProductsContainer id="products">
      <ProductsH1>Nos prochaines enchères</ProductsH1>

      {
        loading ? <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 450,
        }}>
          <GridLoader color='#FF6B00' size={40} />
        </div> : (filteredBids.length === 0) ?
          <NoBidsWrapper style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img alt="No Bids" src={noBids} style={{
              width: '70%',
              height: '70%'
            }} />
            <NoProductsMessage>Pas d'enchère pour le moment, nous publions de <br /> nouveaux enchères dès que possible.</NoProductsMessage>
          </NoBidsWrapper>
          : <>
            <ProductsWrapper>
              {
                filteredBids.map((bid, index) => {

                  const date = new Date(bid.start_time)

                  function n(n) {
                    return n > 9 ? "" + n : "0" + n;
                  }

                  return (
                    <ProductCard key={index}>
                      <DateCard>
                        <ProductStartDate>
                          Prévue le {`${n(date.getDate())}/${n(date.getMonth() + 1)}/${date.getFullYear()}`} à {`${n(date.getHours())}:${n(date.getMinutes())}`}
                        </ProductStartDate>
                      </DateCard>

                      <ProductCategory>
                        {bid.category.name}
                      </ProductCategory>

                      <ProductPhoto src={bid.product.images[0].original} />

                      <hr
                        style={{
                          width: '70%',
                          height: '0.5px',
                          background: 'rgba(30, 39, 46, 0.1)',
                          borderRadius: '1px'
                        }}
                      />

                      <BidDetails>
                        <div style={{
                          height: '60px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <ProductName to={`/bid/${bid._id}`}>
                            {bid.product.name}
                          </ProductName>
                        </div>

                        <div style={{
                          height: '49px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <ProductDescription>
                            {bid.product.description}
                          </ProductDescription>
                        </div>
                        <ProductPrice>
                          <StartPriceText>Prix magasin:</StartPriceText>
                          <StartPriceValue>{bid.product.price + ' TND'}</StartPriceValue>
                        </ProductPrice>

                        <BidRoom>
                          <BidRoomText>Salle d'enchère est remplie à</BidRoomText>

                          <ProgressBar completed={bid.room_participations} bgColor='#FF6B00' />
                        </BidRoom>

                        {
                          bid.isPurchased ?
                            <RegisterButton
                              disabled={true}
                              onClick={() => {
                                console.log('participé')
                              }}>
                              Enchère <br /> acheté
                            </RegisterButton>
                            :
                            <RegisterButton
                              disabled={false}
                              onClick={() => {
                                payer({
                                  bidId: bid._id,
                                  ddp: bid.product.name,
                                  subTotal: bid.participation_price,
                                  total: bid.participation_price
                                })
                              }}>
                              Participer à <br /> {bid.participation_price + ' TND'}
                            </RegisterButton>
                        }
                      </BidDetails>

                    </ProductCard>
                  )
                })
              }
            </ProductsWrapper>

          </>
      }
    </ProductsContainer>
  )
}

export default ProductSection