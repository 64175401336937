import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import HeroSection from '../components/HeroSection';
import CategoriesSection from '../components/CategoriesSection';
import ProductSection from '../components/ProductsSection';
import Footer from '../components/Footer';
import InfoSection from '../components/InfoSection';
import { HomeObjOne, HomeObjTwo } from '../components/InfoSection/Data';
import ScrollToTop from '../components/ScrollToTop';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [category, setCategory] = useState('all')

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <CategoriesSection category={category} setCategory={setCategory} />
            <ProductSection category={category} setCategory={setCategory} />
            <InfoSection {...HomeObjOne} />
            <InfoSection {...HomeObjTwo} />
            <Footer />
        </>
    )
}

export default Home