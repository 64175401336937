import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import { useParams } from 'react-router-dom'
import { BsBellFill } from 'react-icons/bs'
import { RiAuctionFill } from 'react-icons/ri'
import {
    BidContainer,
    BidWrapper,
    InputOfferSection,
    LeftSection,
    OfferSection,
    RightSection,
    ProductTitle,
    ProductDescription,
    BidRoom,
    BidRoomText,
    ParticipationButton,
    RegisterButton,
    ProductPrice,
    Text,
    Span,
    StartPriceValue,
    BidOfferInput,
    FormButton,
    CountdownWrapper,
    PriceCountdown,
    BuzzerButton,
    Image,
    StartPriceText,
    OfferDescription,
    Descendent,
    DescendentText
} from './bidDetailsElements'
import trophy from '../../images/trophy.png'
import ProgressBar from '@ramonak/react-progress-bar'
import GridLoader from "react-spinners/GridLoader";
import MoonLoader from "react-spinners/MoonLoader";
import { toast } from 'react-toastify'
import { axiosInstance } from '../../app/api';
import NotFound404 from '../NotFound';
import socketIOClient from "socket.io-client";

function BidDetails() {

    const API_URL = '/api/bid-rooms/'
    const { id } = useParams()
    const [bid, setBid] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingOffer, setLoadingOffer] = useState(false)
    const [bidPurchased, setBidPurchased] = useState(false)

    const ENDPOINT = "https://mise.tn";
    // const ENDPOINT = "http://localhost:5000";
    const [response, setResponse] = useState("");

    const { user } = useSelector((state) => state.auth)

    // 0: Not purchased ; 1: Purchased but not started ; 2: Purchased and started ; 3 finished
    const [status, setStatus] = useState()
    const [offer, setOffer] = useState(0);
    const [defaultOffer, setDefaultOffer] = useState(0);
    const socket = socketIOClient(ENDPOINT);

    const navigate = useNavigate()

    const payer = (transaction) => {
        if (user) {
            window.localStorage.setItem('chosen_bid', JSON.stringify(transaction))
            navigate('/paiement-validation')
        }
        else
            navigate('/sign-in')
    }

    const getBidStatus = ({ status }) => {

        if (!user) {
            if (status === 1)
                setStatus(2)
            else if (status === 2)
                setStatus(3)
            else
                setStatus(0)
        }

        else {
            if (status === 0) {
                if (!bidPurchased) {
                    setStatus(0)
                }
                else {
                    // getUserPriceOffer()
                    setStatus(1)
                }

            } else if (status === 1) {
                setStatus(2)
            } else if (status === 2) {
                setStatus(3)

            } else {
                setStatus(0)
            }
        }
    }

    const getPurchased = async () => {
        await axiosInstance.get(`/api/bid-participations/${user._id}/${id}`)
            .then((res) => {
                setOffer(res.data.price_offer)
                setDefaultOffer(res.data.price_offer)
                setBidPurchased(true)
            })
            .catch((e) => {
                setBidPurchased(false)
            })
    }

    const getBid = async () => {
        setLoading(true)

        getPurchased()

        const response = await axiosInstance.get(API_URL + id)

        getBidStatus(response.data)

        setBid(response.data)

        setLoading(false)
    }

    useEffect(() => {
        getBid()
    }, [])

    useEffect(() => {
        socket.on(`bid-${id}`, (data) => {
            if (data == "done") {
                navigate('/finished-bids')
            }
            else
                setResponse(data);
        });

    }, []);


    const Loading = () => {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 650,
            }}>
                <GridLoader color='#FF6B00' size={40} />
            </div>
        )
    }

    const updateOffer = async () => {
        if (offer > bid.product.price) {
            toast.error(`Vous devez proposer une offre inférieur à ${bid.product.price} DT`)
        } else if (offer < 1) {
            toast.error(`Vous devez proposer une offre supérieur à 0 DT`)
        } else {
            setLoadingOffer(true)
            await axiosInstance.put(`/api/bid-participations/${user._id}/${id}`, { price_offer: offer })
                .then((res) => {
                    toast.success('Votre offre a été changé')
                    navigate('/')
                })
                .catch((e) => {
                    toast.error(e.response.data.message)
                })
            setLoadingOffer(false)
        }
    }

    return (
        <div>
            {loading ? <Loading /> : bid.message ? <NotFound404 /> : (
                <BidContainer>
                    {/* <BidWrapper> */}
                    <LeftSection>

                        <ImageGallery
                            items={bid.product.images}
                            showFullscreenButton={false}
                            showPlayButton={false}
                        />

                        {
                            status !== 3 && (
                                <Descendent>
                                    <BsBellFill size={25} color="#FF6B00" style={{ marginRight: 10 }} />
                                    <DescendentText>
                                        Cette enchère descend de <Span>{bid.decrement_price + ' TND'}</Span>
                                        chaque <Span>{bid.decrement_seconds + ' seconde(s)'}</Span>
                                    </DescendentText>
                                </Descendent>
                            )
                        }
                    </LeftSection>

                    <RightSection>
                        <ProductTitle>{bid.product.name}</ProductTitle>

                        {
                            status === 2 ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <CountdownWrapper>
                                        <PriceCountdown>{response}</PriceCountdown> <Span>TND</Span>
                                    </CountdownWrapper>
                                    {
                                        ((!user && (bid.status === 1)) || (user && !bidPurchased)) ? null :
                                            <BuzzerButton onClick={() => {
                                                socket.emit("finish-bid", {
                                                    winner: user._id,
                                                    new_price: response,
                                                    bidroom: id
                                                })
                                            }}>
                                                {
                                                    loading ?
                                                        <MoonLoader color='white' /> :
                                                        <RiAuctionFill size={100} color="white" />
                                                }
                                            </BuzzerButton>
                                    }

                                </div>

                                :

                                <div>
                                    {
                                        !bidPurchased && status !== 3 ? (
                                            <ProductDescription>
                                                {bid.product.description}
                                            </ProductDescription>
                                        )
                                            : bidPurchased && status !== 3 ?
                                                (

                                                    <OfferSection style={{
                                                        backgroundColor: '#2ecc71'
                                                    }}>

                                                        {
                                                            defaultOffer === 0 ?
                                                                <OfferDescription>
                                                                    Vous pouvez proposer votre offre de prix pour l'acquisition de ce produit,
                                                                    cette offre servira uniquement à la date et heure de l'enchère, si le compte
                                                                    à rebours du produit atteint votre offre vous remporterez l'enchère.
                                                                    (C'est-à-dire si au moment de l'enchère vous avez un problème de connection ou un empêchement…
                                                                    notre robot enchéri à votre place)
                                                                    <br />
                                                                    <br />
                                                                    <p style={{
                                                                        backgroundColor: '#e74c3c',
                                                                        fontSize: 12,
                                                                        textAlign: 'center',
                                                                        padding: 5
                                                                    }}>NB: Vous n'avez qu'une seule tentative pour choisir un prix d'offre
                                                                        et vous pouvez toujours enchérir en direct</p>
                                                                </OfferDescription>
                                                                :

                                                                <h2 style={{
                                                                    color: 'white',
                                                                    textAlign: 'center'
                                                                }}>Prix proposé par vous: {defaultOffer} DT</h2>
                                                        }
                                                        {
                                                            defaultOffer === 0 && (
                                                                <InputOfferSection>
                                                                    <BidOfferInput
                                                                        placeholder="Votre offre d'enchère"
                                                                        disabled={defaultOffer > 0 ? true : false}
                                                                        value={offer}
                                                                        type="number"
                                                                        onChange={(event) => {
                                                                            setOffer(event.target.value)
                                                                        }}
                                                                    />
                                                                    <FormButton disabled={loadingOffer ? true : false} onClick={() => {
                                                                        updateOffer()
                                                                    }}>{loadingOffer ? 'loading...' : 'Valider'}</FormButton>
                                                                </InputOfferSection>
                                                            )
                                                        }

                                                    </OfferSection>
                                                ) : null
                                    }

                                    {
                                        status === 3 ? (
                                            <ProductPrice>
                                                <Image src={trophy} />
                                                <StartPriceValue green>Enchère remporté à: {bid.new_price + ' TND'}</StartPriceValue>
                                                <StartPriceText>Par: {bid.winner}</StartPriceText>

                                            </ProductPrice>
                                        ) : (
                                            <ProductPrice>
                                                {/* <StartPriceText></StartPriceText> */}
                                                <StartPriceValue>Prix magasin: {bid.product.price + ' TND'}</StartPriceValue>
                                            </ProductPrice>
                                        )
                                    }


                                    {
                                        status !== 3 && (
                                            <hr
                                                style={{
                                                    marginTop: '10%',
                                                    height: '1px',
                                                    background: '#1e272e',
                                                    border: 'none',
                                                    borderRadius: '1px'
                                                }}
                                            />
                                        )
                                    }
                                    <div>
                                        {
                                            status !== 3 && (
                                                <BidRoom>
                                                    <BidRoomText>Salle d'enchère est remplie à {(Math.round(bid.room_participations * 100) / 100).toFixed(2)}%</BidRoomText>
                                                    <ProgressBar completed={bid.room_participations} bgColor='#FF6B00' />
                                                </BidRoom>
                                            )
                                        }
                                        {
                                            status !== 3 && (
                                                <hr
                                                    style={{
                                                        marginTop: '5%',
                                                        height: '1px',
                                                        background: '#1e272e',
                                                        border: 'none',
                                                        borderRadius: '1px'
                                                    }}
                                                />
                                            )
                                        }
                                        {(!bidPurchased && status !== 3) && (
                                            <ParticipationButton>
                                                <RegisterButton onClick={() => {
                                                    payer({
                                                        bidId: bid._id,
                                                        ddp: bid.product.name,
                                                        subTotal: bid.participation_price,
                                                        total: bid.participation_price
                                                    })
                                                }}>Participer à <br />{bid.participation_price + ' TND'}</RegisterButton>
                                            </ParticipationButton>
                                        )}

                                    </div>
                                </div>
                        }


                    </RightSection>


                    {/* </BidWrapper> */}
                </BidContainer>
            )}
        </div>
    )
}

export default BidDetails