import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideAuthMenu,
    SideBtnWrap,
    SidebarRoute,
    SidebarRouteOutline
} from './SidebarElements'
import { NavBtnLinkOutline, SignOutBtn } from '../Navbar/NavbarElements'

const Sidebar = ({ isOpen, toggle }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/')
    }

    return (
        <>
            <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <Icon onClick={toggle}>
                    <CloseIcon />
                </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                        <SidebarLink to="/">
                            Accueil
                        </SidebarLink>
                        <SidebarLink to="/on-going-bids">
                            Enchères en cours
                        </SidebarLink>
                        <SidebarLink to="/finished-bids">
                            Enchères terminées
                        </SidebarLink>
                        <SidebarLink to="/how-it-works">
                            Comment ça marche
                        </SidebarLink>
                    </SidebarMenu>

                    {user ?

                        <SideAuthMenu>
                            <SideBtnWrap>
                                <NavBtnLinkOutline to="/profile">{user.username}</NavBtnLinkOutline>
                            </SideBtnWrap>
                            <SideBtnWrap>
                                <SignOutBtn onClick={onLogout}>Se déconnecter</SignOutBtn>
                            </SideBtnWrap>
                        </SideAuthMenu>
                        :

                        <SideAuthMenu>
                            <SideBtnWrap>
                                <SidebarRoute to="/sign-in">Se connecter</SidebarRoute>
                            </SideBtnWrap>
                            <SideBtnWrap>
                                <SidebarRouteOutline to="/sign-up">Créer un compte</SidebarRouteOutline>
                            </SideBtnWrap>
                        </SideAuthMenu>
                    }
                </SidebarWrapper>
            </SidebarContainer>
        </>
    )
}

export default Sidebar