import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(
        108deg,
        rgba(30, 39, 46, 1.0) 0%,
        rgba(26, 32, 36, 1) 100%
    );
`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const IconLogo = styled.img`
    width: 50%;
    height: 25%;
`

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    position: absolute;
    text-decoration: none;
    color: #fff;
    width: 20%;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px) {
        margin-left: 16px;
        margin-top: 8px;
    }
`

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`

export const Form = styled.form`
    background: #FF6B00;
    max-width: 600px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 40px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0, 0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    width: 100%;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
`

export const FormButton = styled.button`
    background: ${props => props.disabled ? "rgba(30, 39, 46,0.5)" : "rgba(30, 39, 46,1.0)"};
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: ${props => props.disabled ? "rgba(255, 255, 255,0.5)" : "#fff"};
    font-size: 20px;
    cursor: ${props => props.disabled ? null : "pointer"};

    &:hover {
        background:${props => props.disabled ? "rgba(30, 39, 46,0.5)" : "#fff"};
        color: ${props => props.disabled ? "rgba(255, 255, 255,0.5)" : "#000"};
        transition: all 0.2s ease-in-out;
    }
`

export const Text = styled(Link)`
    text-align: justify;
    margin-top: 15px;
    color: rgba(30, 39, 46,1.0);
    font-size: 14px;
    font-weight: 400;
    &:hover {
        color: rgba(30, 39, 46,1.0);
        transition: all 0.2s ease-in-out;
    }
`

export const ConditionsText = styled.p`
    margin-top: 15px;
    font-size: 14px;
    color: #fff;
`