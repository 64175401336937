import React, { useState, useEffect } from 'react'
import Logo from '../../images/logo-dark.png'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { sendResetLink, reset } from '../../features/auth/authSlice'
import ClipLoader from "react-spinners/ClipLoader";
import {
    Container,
    Form,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormWrap,
    Icon,
    IconLogo
} from '../Signin/SigninElements'

const ForgotPassword = () => {

    const [formData, setFormData] = useState({
        email: ''
    })

    const { email } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        if (isSuccess || user) {
            navigate('/')
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            email
        }

        dispatch(sendResetLink(userData))
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/"><IconLogo src={Logo} /></Icon>
                    <FormContent>
                        <Form onSubmit={onSubmit}>
                            <FormH1>Mot de passe oublié?</FormH1>
                            {/* <FormLabel htmlFor='for'>Email</FormLabel> */}
                            <FormInput
                                type='email'
                                placeholder='Email'
                                id='email'
                                name='email'
                                value={email}
                                onChange={onChange}
                                required
                            />

                            <FormButton>
                                {!isLoading ?
                                    "Envoyer" :
                                    <ClipLoader color='orange' size={20} />
                                }
                            </FormButton>
                        </Form>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    )
}

export default ForgotPassword