import React from 'react'
import privacy from '../../images/privacyPolicy.png'
import { BidContainer, H1, P, Image } from './PrivacyElements'

function PrivacyPolicy() {
    return (
        <div>
            <BidContainer>
                <H1>Politique de confidentialité</H1>

                <div style={{
                    marginTop: 25
                }}>
                    <P>Conformément à la règlementation « <strong>Loi organique n° 2004-63 du 27 juillet 2004</strong> », portant sur la protection des données 
                        à caractère personnel. « <strong>Article premier</strong> : Toute personne a le droit à la protection des données à caractère 
                        personnel relatives à sa vie privée comme étant l'un des droits fondamentaux garantis par la constitution et ne peuvent 
                        être traitées que dans le cadre de la transparence, la loyauté et le respect de la dignité humaine et conformément 
                        aux dispositions de la présente loi. ».</P>

                    <P>Nous nous engageons à protéger toutes les informations qui peuvent être collectées à travers notre Plateforme « <strong>Mise.tn</strong> ».</P>

                    <P>La Société <strong>BARGAIN TRADE</strong> est responsable de la collecte et du traitement de vos données personnelles dans le cadre de l'utilisation 
                        que vous faites sur notre Plateforme. Notre fournisseur de service de paiement est aussi responsable de la collecte et du traitement 
                        de vos données personnelles afin de finaliser les transactions avec vous.</P>

                    <P>Nous recueillons vos données personnelles lorsque vous utilisez notre Application, ouvrez un compte « <strong>Mise.tn</strong> », vous nous fournissez des informations au moyen d'un formulaire Web.</P>

                    <P>Les données à caractère personnel collectées sur notre Plateforme « <strong>Mise.tn</strong> » sont les suivantes 
                        (Nom, Prénom, Date de naissance, Adresse e-mail, N° du portable). Par ailleurs, les fournisseurs 
                        de services de paiement collectent les données bancaires ou postales.</P>

                    <P>Les informations collectées seront traitées conformément aux dispositions de la <strong>loi organique n° 2004-63 du 24 juillet 2004</strong> 
                        portant sur la protection des données à caractère personnel. La Société  s'engage à traiter ces données loyalement, 
                        aux regards des finalités pour lesquelles elles ont été collectées, Par exemple, nous traitons vos données personnelles 
                        pour fournir et améliorer notre Plateforme, vous contacter au sujet de votre compte « <strong>Mise.tn</strong> », vous fournir un service clients, 
                        vous proposer des publicités et des communications marketing, et détecter, prévenir et limiter les activités frauduleuses ou illégales, 
                        ou enquêter sur ces activités. La Société <strong>BARGAIN TRADE</strong> s'engage également de ne pas communiquer les données collectées aux tiers.</P>

                    <P>Cette politique résume vos droits en matière de confidentialité concernant la collecte et l'utilisation de vos informations personnelles.</P>

                    <P>En installant et en utilisant cette application, vous acceptez que nous utilisions vos informations personnelles comme
                        résumé ci-dessous et comme décrit dans notre <a href='privacy-policy'>politique de confidentialité</a> complète,
                        et vous acceptez d'être lié par les <a href='terms-and-conditions'>Conditions Générale d'Utilisation</a>.</P>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20
                }}>
                    <Image src={privacy} />
                </div>
                <div style={{
                    marginTop: 20
                }}>
                    <P>La présente politique de confidentialité peut être consultée à tout moment sur la Plateforme « <strong>Mise.tn</strong> ». 
                        L'éditeur de cette Plateforme se réserve le droit de modifier la politique de confidentialité afin de garantir sa conformité 
                        avec le droit en vigueur. Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité.</P>
                    <P>L'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en accepte les conditions.</P>
                </div>

                <H1>PREAMBULE - Qu'est-ce que l'application « Mise.tn »</H1>
                <div style={{
                    marginTop: 25
                }}>

                        <P>
                        <strong>Mise.tn</strong> vous propose un service de vente aux enchères en ligne par la <strong>Société Bargain Trade</strong> (ci-après « <strong>S.B.T.</strong> »), 
                        société à responsabilité limité, Matricule Fiscale numéro 1790199 K / N / M / 000, 
                        Registre de Commerce numéro C03396612022 dont le siège social est à l'Avenue Jugurtha N° 78 – Mutuelleville – 1082 Tunis(ie). 
                        E-mail : contact@mise.tn, Téléphone : 25 87 55 55.
                        </P>

                    <P>La <strong>S.B.T.</strong> permet à ses participants d'acheter pratiquement tout type d'article aux meilleurs prix qui soient.</P>
                    <P>La <strong>S.B.T.</strong> ne détient aucun article mis en vente sur son site, mais les ventes sont conclues aux enchères descendantes 
                    et en direct (en ligne), et ces objets seront collectés directement de chez les fournisseurs à partir du troisième (3éme) jour 
                    de la date du paiement (bien sûr jours ouvrables)</P>
                    <P>Toutes les transactions opérées sur la plateforme <strong>Mise.tn</strong> sont gérées par <strong>Konnect Network</strong>, qui est un établissement agréé.</P>
                    <P>Les moyens de paiement suivants sont autorisés sur <strong>Mise.tn</strong> :</P>
                    <ul style={{
                        marginLeft: 28
                    }}>
                        <li>Cartes bancaires et Postales (notamment les cartes MasterCard, Visa)</li>
                        <li>Transferts de banque à banque (aussi appelés "virements bancaires")</li>
                        <li>Espèces pour les sommes ne dépassant pas les 3000 Dinars et pour les transactions en mains propres</li>
                        <li>Chèques postal ou bancaire (chèques certifiés)</li>
                    </ul>
                </div>
            </BidContainer>
        </div>
    )
}

export default PrivacyPolicy