import React from 'react'
import { BidContainer, H1, P, H2 } from './PrivacyElements'

function LegalNotice() {
    return (
        <div>
            <BidContainer>
                <H1>Mentions légales</H1>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <ul>
                        <li>Conformément à la règlementation « <strong>Loi n° 2000-83 du 9 août 2000, Relative aux échanges et au commerce électroniques</strong> », la présente loi fixe les règles générales régissant les échanges et le commerce électroniques (e-Commerce).</li>
                        <li>La <strong>Société Bargain Trade</strong> (ci-après « <strong>S.B.T.</strong> »), exploite une Plateforme de commerce électronique (e-commerce) sur le territoire Tunisien nommée « <strong>Mise.tn</strong> » composée d'un Site Web et d'une Application mobile.</li>
                        <li>La « <strong>S.B.T.</strong> » permet à ses participants d'acheter pratiquement tout type d'article aux meilleurs prix qui soient. Vous souhaitez réaliser de bonnes affaires, ne ratez pas nos enchères, une large sélection d'articles neufs et de service mis en ligne (High-tech « Smartphone, Tablette, PC, TV, Consoles de jeux, … », Electroménagères, Matériels professionnels pour « Cafétéria, Restauration, Pâtisserie, Boulangerie, Hôtellerie, … », Autos, Motos, Loisirs « Voyages, Vacances, … », Mobiliers, Immobiliers « Terrains, Appartements, …), Services « Soins du visage, des mains et pieds, Coiffures, … », et autres à des Prix réduits. <strong>Mise.tn</strong> vous permettra de profiter aux mieux de nos produits remisés adaptés à votre budget, chose qu'aucun fournisseur ne pourra vous accordez.</li>
                    </ul>
                </div>

                <H2>Éditeur</H2>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <ul>
                        <li>Société Bargain Trade</li>
                        <li>Société à responsabilité limité (S.A.R.L)</li>
                        <li>Matricule Fiscale (MF) : 1790199 K / N / M / 000</li>
                        <li>Registre de Commerce (RC) : C03396612022</li>
                        <li>Siège social : Avenue Jugurtha N° 78 – Mutuelleville – 1082 Tunis(ie).</li>
                        <li>Responsable: Mr. B.Hamouda Med Habib</li>
                        <li>E-mail : contact@mise.tn</li>
                        <li>Tel : 25 87 55 55.</li>
                    </ul>
                </div>

                <H2>Hébergeur</H2>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <ul>
                        <li>OXAHOST</li>
                        <li>Société à responsabilité limité (S.A.R.L)</li>
                        <li>Matricule Fiscale (MF) : 1550168 / A / M / 000</li>
                        <li>Registre de Commerce (RC) : B0318802018</li>
                        <li>Siège social : Complexe Résidence Jinène du Lac 2 – Bureau A12 – Lac 2, 1053 Tunis, Tunisie.</li>
                        <li>E-mail : help@oxahost.support</li>
                        <li>Tel : 70 168 620.</li>
                    </ul>
                </div>

                <H2>Service paiement</H2>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <ul>
                        <li>KONNECT NETWORKS</li>
                        <li>E-mail : contact@konnect.network</li>
                        <li>Tel : 24 563 609.</li>
                    </ul>
                </div>

                <H2>Conception</H2>

                <div style={{
                    marginTop: 25,
                    marginLeft: 30
                }}>
                    <ul>
                        <li>Société Bargain Trade</li>
                        <li>Mr. B. Hamouda Med Habib</li>
                        <li>E-mail : contact@mise.tn</li>
                        <li>Tel : 25 87 55 55.</li>
                    </ul>
                </div>

                <H2>Services fournis</H2>

                <div style={{
                    marginLeft: 10
                }}>
                    <P>
                        La Société <strong>Bargain Trade</strong> (ci-après « <strong>S.B.T.</strong> »), s'efforce de fournir sur l'Application « <strong>Mise.tn</strong> » des informations aussi précises que possible.
                    </P>

                    <P>
                        L'Application remise à jour à différentes périodes de l'année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui paraît être un dysfonctionnement, merci de bien vouloir le signaler par courrier, à l'adresse support@mise.tn, en décrivant le problème de la manière la plus précise possible (page posant problème, type d'ordinateur et de navigateur utilisé, …).
                    </P>

                    <P>
                        Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, la « <strong>S.B.T.</strong> » ne saurait être tenue pour responsable d'un quelconque dommage subi à l'ordinateur de l'utilisateur ou d'une quelconque perte de données.
                    </P>
                </div>

                <H2>Litiges</H2>

                <div style={{
                    marginLeft: 10
                }}>
                    <P>
                        Les présentes conditions de la Plateforme « <strong>Mise.tn</strong> » sont régies par les lois Tunisiennes et toute contestation ou litige qui pourrait naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société.
                    </P>
                </div>

                <H2>Données personnelles</H2>

                <div style={{
                    marginLeft: 10
                }}>
                    <P>
                        De manière générale, vous n'êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre Plateforme « <strong>Mise.tn</strong> ».
                    </P>

                    <P>
                        Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre Site Web et Application, vous pouvez être amenés à nous communiquer certaines données telles que : votre Nom, Prénom, Date de naissance, Adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « Créer un compte ».
                    </P>

                    <P>
                        Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services de la Plateforme.
                    </P>

                    <P>
                        Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d'une simple navigation sur notre Plateforme, notamment : des informations concernant les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d'accès.
                    </P>

                    <P>
                        De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la « <strong>Loi organique n° 2004-63 du 27 juillet 2004</strong> », portant sur la protection des données à caractère personnel.
                    </P>
                </div>
            </BidContainer>
        </div>
    )
}

export default LegalNotice