import styled from "styled-components"
import { Link } from "react-router-dom";

export const ProductsContainer = styled.div`
    background: #fff;
    display: flex;
    height: 700px;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px;
    width: 100%;
    position: relative;
    z-index: 1;
`

export const ProductsH1 = styled.h1`
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 10px;
    letter-spacing: -1px;
    color: #34495E;
`

export const ProductsWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Table = styled.table`
    background: #34495E;
    color: #fff;
    /* width: 500px; */
    margin-bottom: 80px;
    border-radius: .4em;
    overflow: hidden;
    width: 100%;
`

export const Tr = styled.tr`
    border-color: lighten(#34495E, 10%);
`

export const Th = styled.th`
    padding: .5em 1em;
    text-align: left;
    color: aliceblue;
    @media (min-width: 480px) { 
      padding: 1em !important; 
    }
    &:before {
        color: #dd5;
    }
`

export const Td = styled.td`
    padding: .5em 1em;
    @media (min-width: 480px) { 
      padding: 1em !important; 
    }
    &:before {
        color: #dd5;
    }
`

export const Span = styled.span`
    font-size: 10px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: white;
    height: 70px;
    text-align: center;
    background: #FF6B00;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
    transition: all 0.2s ease-in-out;
    background: #27ae60;
    }
`

export const ButtonWrapperLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: white;
    height: 70px;
    text-align: center;
    background: #27ae60;
    border-radius: 6px;
`