import React, { useState, useEffect } from 'react'
import { BidContainer, H1 } from './PrivacyElements'
import { useSelector } from 'react-redux'
import { axiosInstance } from '../../app/api';

function ProfileComponent() {

    const { user } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const getUser = async () => {
        await axiosInstance.get('/api/users/me', {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then((res) => {
            setData(res.data)
        })
        setLoading(false)
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div>
            <BidContainer>
                <H1>Profile</H1>

                {
                    !loading && (
                        <div style={{
                            marginTop: 50,
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid rgba(189, 195, 199,0.8)',
                                width: '40%',
                                backgroundColor: 'rgba(189, 195, 199,0.3)',
                                borderRadius: 15,
                                paddingLeft: 15,
                                height: 50
                            }}>
                                <p>{data.firstName}</p>
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 12,
                                border: '1px solid rgba(189, 195, 199,0.8)',
                                width: '40%',
                                backgroundColor: 'rgba(189, 195, 199,0.3)',
                                borderRadius: 15,
                                paddingLeft: 15,
                                height: 50
                            }}>
                                <p>{data.lastName}</p>
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 12,
                                border: '1px solid rgba(189, 195, 199,0.8)',
                                width: '40%',
                                backgroundColor: 'rgba(189, 195, 199,0.3)',
                                borderRadius: 15,
                                paddingLeft: 15,
                                height: 50
                            }}>
                                <p>{data.email}</p>
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 12,
                                border: '1px solid rgba(189, 195, 199,0.8)',
                                width: '40%',
                                backgroundColor: 'rgba(189, 195, 199,0.3)',
                                borderRadius: 15,
                                paddingLeft: 15,
                                height: 50
                            }}>
                                <p>{data.phoneNumber}</p>
                            </div>
                        </div>
                    )
                }
            </BidContainer>
        </div>
    )
}

export default ProfileComponent