import React from 'react'
import NotFound from '../../images/not-found.svg'
import { BidContainer, H1, Image } from './NotFoundElements'

function NotFound404() {
    return (
        <div>
            <BidContainer>
                <Image src={NotFound} />
                <H1>NOT FOUND</H1>
            </BidContainer>
        </div>
    )
}

export default NotFound404