import React, { useState } from 'react'
import ScrollToTop from '../components/ScrollToTop'
import EmailConfirmation from '../components/EmailConfirmation'

function EmailConfirmationPage() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <ScrollToTop />
            <EmailConfirmation />
        </div>
    )
}

export default EmailConfirmationPage