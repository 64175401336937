import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { login, reset } from '../../features/auth/authSlice'
import ClipLoader from "react-spinners/ClipLoader";
import Logo from '../../images/logo-dark.png'
import {
    Container,
    Form,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormLabel,
    FormWrap,
    Icon,
    Text,
    IconLogo
} from './SigninElements'

const SignIn = () => {

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const { email, password } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        if (isSuccess || user) {
            navigate('/')
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            email,
            password
        }

        dispatch(login(userData))
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/"><IconLogo src={Logo} /></Icon>

                    <FormContent>
                        <Form onSubmit={onSubmit}>
                            <FormH1>S'identifier</FormH1>
                            <FormLabel htmlFor='for'>Email</FormLabel>
                            <FormInput
                                type='email'
                                id='email'
                                name='email'
                                value={email}
                                onChange={onChange}
                                required
                            />
                            <FormLabel htmlFor='for'>Mot de passe</FormLabel>
                            <FormInput
                                type='password'
                                id='password'
                                name='password'
                                value={password}
                                onChange={onChange}
                                required
                            />

                            <FormButton disabled={isLoading ? true : false}>
                                {isLoading ?
                                    <ClipLoader color='orange' size={20} />
                                    :
                                    'Se connecter'
                                }

                            </FormButton>
                            <Text to='/reset-password'>Mot de passe oublié?</Text>
                        </Form>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    )
}

export default SignIn