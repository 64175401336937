import React from 'react'
import privacy from '../../images/privacyPolicy.png'
import { BidContainer, H1, P, Image } from './PrivacyElements'

function HowItWorks() {
    return (
        <div>
            <BidContainer>
                <H1>Comment ça marche</H1>

                <div style={{
                    marginTop: 25,
                    marginLeft: 35
                }}>
                    <ol style={{
                        lineHeight: 2
                    }}>
                        <li>Vous devez créer votre compte (Inscription), veillez à bien remplir le formulaire.</li>
                        <li>Consultez les articles sur notre plateforme et choisir le produit que vous désirez acquérir</li>
                        <li>Inscrivez-vous dans la salle d'enchère en payant les frais de participation.</li>
                        <li>Cette étape est facultative et aussi importante, vous pouvez proposer votre offre de prix pour l'acquisition de ce produit. </li>
                        <li>Attendre la date et heure de l'enchère.</li>
                        <li style={{
                            lineHeight: 1.5
                        }}>
                            L'enchère commence à la date, heure et minute prévus, le prix de départ du produit mis en enchère est égal au Prix affiché sur notre plateforme. 
                            Puisqu'il s'agit d'une <strong>enchère descendante</strong>, dès qu'elle débute, le Prix de l'article est déduit de <strong>(X) TND</strong> toute les <strong>(Y) secondes</strong> (le montant de déduction 
                            est affiché sur la page de participation), jusqu'au clic d'un participant ou jusqu'à ce que le montant rencontre une offre, celui-ci remportera l'enchère
                            <br /><strong>NOTEZ BIEN QUE LE PREMIER QUI ENCHERI (VALIDE) REMPORTE L'ENCHERE VU QUE C'EST UNE ENCHERE DESCENDANTE.</strong></li>
                        <li>Une fois l'enchère terminée le gagnant sera contacté par l'équipe Mise.tn afin de confirmer son achat.</li>
                        <li>Le participant qui a remporté l'enchère a <strong>10 jours ouvrables</strong> (à partir de la date de l'enchère) pour payer 
                            le montant de l'article enchéri. Vous avez la possibilité de payer en <strong>espèces</strong>, chez « <strong>Société BARGAIN TRADE</strong> », 
                            par virement bancaire ou postale sur le compte de la « <strong>Société BARGAIN TRADE</strong> » et par <strong>chèques personnels ou sociétés 
                            (chèques certifiés)</strong>.</li>
                        <li>La livraison sera assurée selon les conditions commerciales du fournisseur du produit (délais et prix gratuite ou payante).</li>
                    </ol>
                </div>

                <div style={{
                    marginTop: 15,
                    marginLeft: 35
                }}>
                    <ul style={{
                        lineHeight: 2
                    }}>
                        <li><strong>TND:</strong> Dinar Tunisien</li>
                        <li><strong>X:</strong> variable du montant de déduction (5, 10, 25, 50, 100, …) suivant la valeur du produit, (5 TND toutes les 3 secondes ça fait 100 TND par minute, …).</li>
                        <li><strong>Y:</strong> variable des secondes de déduction (1", 2", 3" ou 5") suivant la valeur du produit.</li>
                    </ul>
                </div>

                <H1>Conseil</H1>

                <div style={{
                    marginTop: 25,
                    marginLeft: 35
                }}>
                    <ul style={{
                        lineHeight: 2
                    }}>
                        <li>Mieux vaut se fixer un budget pour lequel on compte acheter l'article:<br />
                        Si vous avez envie d'acheter un smartphone avec un budget ne dépassant pas les 300 TND, et que vous trouverez sur notre site plusieurs choix dont le premier est à 1000 TND et le deuxième est à 400 TND. Afin de maximiser vos chances de remporter l'article, miser sur le deuxième, vous aurez ainsi plus de chance de remporter l'enchère !
                        </li>
                        <li>
                        Dans le cas de planification de la mise, l'offre que vous proposerais sera prise en considération pendant l'enchère ; dès que le compte à rebours du produit atteint votre offre vous remporterez l'enchère. La planification vous évite les risques qui peuvent surgir suite à un problème de connexion ou un empêchement puisque c'est le serveur qui enchérira à votre place.
                        </li>
                    </ul>
                </div>

                <H1>Attention</H1>

                <div style={{
                    marginTop: 25,
                    marginLeft: 35
                }}>
                    <ul style={{
                        lineHeight: 2
                    }}>
                        <li>
                            Si la salle d'enchère n'est pas remplie à 100%, l'enchère sera reportée automatiquement à une date ultérieure. Une fois la salle d'enchère est remplie, vous recevrez un message de confirmation de la date et heure du commencement de l'enchère.
                        </li>
                    </ul>
                </div>
            </BidContainer>
        </div>
    )
}

export default HowItWorks