import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import logo from '../../images/logo-dark.png'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavAuthMenu,
    NavBtn,
    NavBtnLink,
    NavBtnLinkOutline,
    Logo,
    SignOutBtn
} from './NavbarElements'

const Navbar = ({ toggle }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/')
    }

    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/'>
                        <Logo src={logo} />
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='/'>Accueil</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/on-going-bids'>Enchères en cours</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/finished-bids'>Enchères terminées</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/how-it-works'>Comment ça marche</NavLinks>
                        </NavItem>
                    </NavMenu>
                    {user ?
                        <NavAuthMenu>
                            <NavBtn>
                                <NavBtnLinkOutline to='/profile'>{user.username}</NavBtnLinkOutline>
                            </NavBtn>
                            <NavBtn>
                                <SignOutBtn onClick={onLogout}>Se déconnecter</SignOutBtn>
                            </NavBtn>
                        </NavAuthMenu>
                        :

                        <NavAuthMenu>
                            <NavBtn>
                                <NavBtnLink to='/sign-in'>Se connecter</NavBtnLink>
                            </NavBtn>
                            <NavBtn>
                                <NavBtnLinkOutline to='/sign-up'>Créer un compte</NavBtnLinkOutline>
                            </NavBtn>
                        </NavAuthMenu>
                    }
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar